<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { getUserInfo } from "../utils/auth";
import { getId } from "../utils/utils";

const router = useRouter();

const rooms = ref([]);

const { user_id } = getUserInfo();
const UsersRepository = RepositoryFactory.get("users");

async function getUserRooms(userId) {
    try {
        const response = await UsersRepository.getRooms(userId);
        const data = response.data;
        console.log(data.results);
        rooms.value = data.results;
    } catch (error) {
        console.log(error);
    }
}

function joinStream(room) {
    router.push({ path: `/stream/${getId(room.self)}`, query: { roomName: room.name } });
}

async function copyRoomIdToClipboard(event, room) {
    await navigator.clipboard.writeText(getId(room.self));

    // Change icon to "check"
    room.clipboardClicked = true;
    event.preventDefault();
}

getUserRooms(user_id);
</script>

<template>
    <main class="card-wrapper">
        <Card v-for="room in rooms" :key="room.self" class="border-round-xl shadow-3">
            <template #title>
                <h4 class="flex justify-content-between align-items-center m-2">
                    {{ room.name }}
                    <!-- <Button icon="pi pi-times" class="p-button-text ml-6" @click="removeRoom()"></Button> -->
                </h4>
            </template>
            <template #subtitle>
                <div class="flex align-items-center">
                    <p>
                        {{ getId(room.self) }}
                        <Button v-tooltip.focus.bottom="{ value: 'Copied!' }" :icon="room.clipboardClicked ? 'pi pi-check' : 'pi pi-copy'" class="p-button-text p-button-rounded" @click="copyRoomIdToClipboard($event, room)"></Button>
                    </p>
                </div>
            </template>
            <template #content>
                <div class="flex align-items-center justify-content-evenly">
                    <button class="sign-in-button" @click="joinStream(room)">
                        <i class="pi pi-sign-in text-center" style="font-size: 5rem"></i>
                    </button>
                </div>
            </template>
        </Card>
    </main>
</template>

<style scoped>
.sign-in-button {
    background-color: transparent;
    border: none;
}

.sign-in-button i {
    color: var(--primary-color);
}
.sign-in-button i:hover {
    color: #0090d5;
}

.card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    gap: 1.5em;
}
</style>
